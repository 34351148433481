import './slider.scss';
// import 'slick-carousel'


window.addEventListener('DOMContentLoaded', event => {

    let userAgent = navigator.userAgent;
    let browser;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browser = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browser = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browser = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browser = "opera";
    } else if (userAgent.match(/edg/i)) {
        browser = "edge";
    } else {
        browser = "No browser detection";
    }

    const videos = document.querySelectorAll('video[data-sources]');

    if (videos !== undefined && videos.length) {

        const video = videos[0];
        let sources = JSON.parse(video.dataset.sources);

        const windowSize = window.innerWidth;

        const closestResolution = sources.reduce((a, b) => {
            return Math.abs(b.resolution - windowSize) < Math.abs(a.resolution - windowSize) ? b : a;
        });

        $(video).append($(`<source src="${closestResolution.path}" type="${closestResolution.mime}" size="${closestResolution.resolution}">`));

        videos.forEach((item, key, parent) => {
            if (browser === "safari") {
                item.addEventListener("timeupdate", () => {
                    setTimeout(() => {
                        item.classList.add("loaded");
                        $($('.slider-area video')[0]).parent().removeClass('bg-overlay bg-overlay-dark bg_image bg_image--18 d-flex');
                        $($('.slider-area video')[0]).parent().css('background-image', '');
                    }, 75);

                }, {once: true});
            } else {
                item.classList.add("loaded");
                $($('.slider-area video')[0]).parent().removeClass('bg-overlay bg-overlay-dark bg_image bg_image--18 d-flex');
                $($('.slider-area video')[0]).parent().css('background-image', '');
            }
        });
    }

});